const DATA = [
    {
        id: "M",
        link_name: "マネジ",
        area: "マネジメントガイド",
        volume: 17,
        release_date: "2023/11/19",
    },
    {
        id: "T1-1",
        link_name: "知識1-1",
        area: "知識ガイド1",
        volume: 23,
        release_date: "2023/11/28",
    },
    {
        id: "T1-2",
        link_name: "知識1-2",
        area: "知識ガイド1",
        volume: 22,
        release_date: "2023/12/02",
    },
    {
        id: "T1-3",
        link_name: "知識1-3",
        area: "知識ガイド1",
        volume: 22,
        release_date: "2023/12/03",
    },
    {
        id: "T2-1",
        link_name: "知識2-1",
        area: "知識ガイド2",
        volume: 26,
        release_date: "2023/12/05",
    },
    {
        id: "T2-2",
        link_name: "知識2-2",
        area: "知識ガイド2",
        volume: 26,
        release_date: "2023/12/09",
    },
    {
        id: "T3-1",
        link_name: "知識3-1",
        area: "知識ガイド3",
        volume: 19,
        release_date: "2023/12/09",
    },
    {
        id: "T3-2",
        link_name: "知識3-2",
        area: "知識ガイド3",
        volume: 18,
        release_date: "2023/12/10",
    },
]

export default DATA;
