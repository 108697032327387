import "./App.css";
import SinglePage from "./components/SinglePage";

function App() {
  return (
    <>
      <div className="container">
        <div className="title">
          <a href="/">CPP設問集</a>&nbsp;
          <a href="./cppb202402.pptx">（★2024.2問題例★）</a>
        </div>
      </div>
      <SinglePage />
    </>
  );
}

export default App;
