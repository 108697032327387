import "./SinglePage.css";
import { useEffect, useState } from "react";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Home from "./Home"
import CategoryList from "./CategoryList"
import ErrorPage from "../error-page";

const SinglePage = () => {

  const categoriesURL = "https://cppapi.btime.online/api/category";
  const [categories, setCategories] = useState([]);

  async function getCategories() {
    let res = await fetch(categoriesURL);
    let json = await res.json();
    setCategories(json);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          {categories.map((category) => (
            <div className="category col-3" key={category.id}>
              <Link to={`categorylist/${category.id}`}>{category.category_name}</Link>
            </div>
          ))}
        </div>
      <Routes>
        <Route index element={<Home />} errorElement={<ErrorPage />} />
        <Route path="/categorylist/:id" element={<CategoryList />} />
        {/* reload時に404エラーを検知し、Homeへ戻す */}
        {/* <Route Component={<Home />} /> */}
      </Routes>
    </div>
    </>
  );
}

export default SinglePage;
