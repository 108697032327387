import "./ReleaseTable.css";
import { useReactTable, getHeaderGroups, flexRender, getCoreRowModel } from "@tanstack/react-table"
import DATA from "./data"
import { useState } from "react";


const ReleaseTable = () => {

    const [data, setData] = useState(DATA);

    const columns = [
    {
        accessorKey: 'id',
        header: 'ID',
        cell: (props) => <>{props.getValue()}</>
    },
    {
        accessorKey: 'link_name',
        header: 'リンク名',
        cell: (props) => <>{props.getValue()}</>
    },
    {
        accessorKey: 'area',
        header: '範囲',
        cell: (props) => <>{props.getValue()}</>
    },
    {
        accessorKey: 'volume',
        header: '問題数',
        cell: (props) => <>{props.getValue()}</>
    },
    {
        accessorKey: 'release_date',
        header: 'リリース日',
        cell: (props) => <>{props.getValue()}</>
    },
    ];
  
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    console.log("table.getHeaderGroups:", table.getHeaderGroups());
    
    return (
        <>
            <table className="table" w={table.getTotalSize()}>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                        <th w={header.getSize()} key={header.id}>
                        {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                        )}
                        </th>))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                        <td w={cell.column.getSize()} key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default ReleaseTable
