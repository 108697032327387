import "./QuestionCard.css";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import parse from 'html-react-parser';

const QuestionCard = ({ questionData, onChangeImage }) => {

  const {
    id,
    outline,
    ref_page,
    question,
    hint,
    answer
  } = questionData;

  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <>
      <ReactCardFlip
      className="card"
        isFlipped={isFlipped}
        flipDirection="horizontal"
      >
        <div className="CardFront"
          onClick={() => setIsFlipped((prev) => !prev)}
        >
          {/* 表 */}
          <div className="outline">{outline}&nbsp;【{ref_page}】</div>
          <div className="question">
            <div>
              【設問&nbsp;{id}】
            </div>
            <div>
              {parse(question)}
            </div>
          </div><br />
          <div className="hint">
            {parse(hint)}
          </div>
          </div>
        <div className="CardBack"
          onClick={() => setIsFlipped((prev) => !prev)}
        >
          {/* 裏 */}
          <div className="outline">{outline}&nbsp;【{ref_page}】</div>
          <div className="question">
          <div>
              【設問&nbsp;{id}】
            </div>
            <div>
              {parse(question)}
            </div>
          </div><br />
          <div className="answer">
            {parse(answer)}
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

export default QuestionCard;
