import "./CategoryList.css";
import { useEffect, useState, } from "react";
import { useParams, } from "react-router-dom";
import QuestionCard from "./QuestionCard"

const CategoriesIndexPage = () => {
  // SinglePage.jsxの
  // <Route path="/categorylist/:id" element={<CategoryList />} />
  // より、params.idを受領

  const params = useParams();

  console.log("params.id:", params.id)

  const questionsURL = `https://cppapi.btime.online/api/category/${params.id}`;
  const [questionsData, setQuestionsData] = useState([]);

  async function getCardList() {
    let res = await fetch(questionsURL);
    let json = await res.json();
    setQuestionsData(json);
  }

  useEffect(() => {
    getCardList();
  }, [params.id]);

  return (
    <div>
        {questionsData.map((question) => (
          <div className="CardLayout" key={question.id}>
          <QuestionCard key={question.id} questionData={question} />
          </div>
        ))}
    </div>
  );
}

export default CategoriesIndexPage;
